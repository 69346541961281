<template>
  <v-row dense>
    <v-col cols="12" sm="6">
      <c-input v-model="value.name" :label="$t('shared.name')" :rules="'required|max:30'" />
    </v-col>

    <v-col cols="12" sm="6" class="d-flex align-center">
      <v-switch v-model="value.superAdmin" :label="$t('roles.superAdmin')" inset />
    </v-col>

    <v-col v-if="!branchId" cols="12" sm="6">
      <c-input
        v-model="value.type"
        select
        :items="roleTypes"
        @input="resetPermissionIds"
        :label="$t('shared.type')"
        :rules="'required'"
      />
    </v-col>

    <v-col v-if="!branchId" cols="12" sm="6">
      <auto-complete
        v-if="isOrg"
        v-model="value.organizationId"
        :search="organizationSearch"
        :select="organizationSelect"
        :label="$t('branches.branch')"
        :rules="'required'"
      />
    </v-col>

    <v-col cols="12" sm="6">
      <div class="mb-6">{{ $t('roles.permissionsList') }}</div>

      <v-progress-circular v-if="progressing" indeterminate />

      <div v-else-if="$isEmpty(permissions)" class="font-90 warning--text mb-3">
        <v-icon color="warning"> mdi-exclamation </v-icon>
        {{ $t('shared.noRecords') }}
      </div>

      <v-treeview
        v-else
        selectable
        selection-type="leaf"
        :items="permissions"
        v-model="value.permissionIds"
        selected-color="success"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'EditForm',
  props: {
    value: {type: Object, default: () => ({})}
  },
  data() {
    return {
      progressing: false,
      permissions: [],
      roleTypes: [
        {value: 'ADMIN', text: this.$t('roles.type.ADMIN')},
        {value: 'WORKER', text: this.$t('roles.type.WORKER')},
        {value: 'DOCTOR', text: this.$t('roles.type.DOCTOR')},
        {value: 'ORG', text: this.$t('roles.type.ORG')},
        {value: 'USER', text: this.$t('roles.type.USER')}
      ]
    }
  },
  watch: {
    'value.type': {
      handler() {
        this.getPermissions()
      },
      immediate: true
    }
  },
  computed: {
    branchId() {
      return this.$route.params.branchId
    },
    isOrg() {
      return this.value.type === 'ORG'
    }
  },
  methods: {
    organizationSelect() {
      return Promise.resolve(this.value.organizationInfo)
    },
    resetPermissionIds() {
      this.permissions = []
      this.value.permissionIds = []
      if (!this.isOrg) {
        this.value.organizationId = null
      }
    },
    createTree(list, parentIdFk = null) {
      const children = list.filter((item) => item.parentIdFk === parentIdFk)
      return children.map((item) => {
        let name = null
        if (typeof this.$t(`permissions.${item.name}`) === 'string') {
          name = this.$t(`permissions.${item.name}`)
        } else {
          name = this.$t(`permissions.${item.name}.self`)
        }
        return {
          ...item,
          name,
          children: this.createTree(list, item.id)
        }
      })
    },
    getPermissions() {
      if (!this.value.type) {
        return
      }
      this.progressing = true

      const filter = {
        'sort[0].column': 'id',
        'sort[0].type': 'DESCENDING',
        size: 1000,
        type: this.value.type
      }
      return this.$api.permissions.getAll(filter).then((res) => {
        this.permissions = this.createTree(res)
        this.progressing = false
      })
    },
    organizationSearch(filter) {
      return this.$api.organizations.getAllInfo(filter)
    }
  }
}
</script>
